<template>
  <div>
    <el-card shadow="always" :body-style="{ display: 'none' }">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="8" :sm="8" :xs="10"
            ><el-row type="flex" justify="start"
              ><h4>{{ $route.name }}</h4></el-row
            ></el-col
          >
          <el-col :md="16" :sm="16" :xs="14">
            <el-row type="flex" justify="end">
              <el-col :md="24">
                <el-row :gutter="4" type="flex" justify="end" align="center">
                  <el-col :md="8">
                    <el-select
                      remote
                      value-key="uid"
                      clearable
                      class="m-0"
                      :loading="!cars"
                      @visible-change="fetchCars"
                      @change="selectCar"
                      v-model="car"
                      no-data-text="Não existem veículos cadastrados"
                      size="medium"
                    >
                      <template #prefix>Veículo:</template>
                      <el-option
                        v-for="item in cars"
                        :key="item.uid"
                        :label="item.name"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-button-group>
                    <el-button
                      @click="changeYear(-1)"
                      type="primary"
                      icon="el-icon-d-arrow-left"
                    ></el-button>
                    <el-button
                      @click="changeYear(1)"
                      type="primary"
                      icon="el-icon-d-arrow-right"
                    ></el-button>
                  </el-button-group>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </template>
    </el-card>
    <el-card v-loading="isLoading" shadow="always" class="mt-1">
      <template #header>
        <el-row type="flex" justify="space-between">
          <h4>Consumo dos veículos no ano de {{ currentYear }}</h4>
          <el-button-group>
            <el-button
              @click="changeYear(-1)"
              type="primary"
              icon="el-icon-d-arrow-left"
            ></el-button>
            <el-button
              @click="changeYear(1)"
              type="primary"
              icon="el-icon-d-arrow-right"
            ></el-button>
          </el-button-group>
        </el-row>
      </template>
      <vue-apex-charts
        type="area"
        v-if="ConsumptionGroupedByPane?.length"
        :options="getAreaOptions()"
        :series="ConsumptionGroupedByPane"
      ></vue-apex-charts>
      <el-empty v-else></el-empty>
    </el-card>
    <el-card v-loading="isLoading" shadow="always" class="mt-1">
      <template #header>
        <el-row type="flex" justify="space-between">
          <h4>Rodagem dos veículos no ano de {{ currentYear }}</h4>
        </el-row>
      </template>
      <el-table
        stripe
        :cell-style="() => 'text-align:center;'"
        :data="GroupedByPane"
        style="width: 100%; z-index: 0"
      >
        <el-table-column prop="pane" label="veículo"></el-table-column>
        <el-table-column label="Jan">
          <template #default="s">
            {{ numberFormat.format(s.row[1] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Fev">
          <template #default="s">
            {{ numberFormat.format(s.row[2] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Mar">
          <template #default="s">
            {{ numberFormat.format(s.row[3] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Abr">
          <template #default="s">
            {{ numberFormat.format(s.row[4] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Mai">
          <template #default="s">
            {{ numberFormat.format(s.row[5] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Jun">
          <template #default="s">
            {{ numberFormat.format(s.row[6] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Jul">
          <template #default="s">
            {{ numberFormat.format(s.row[7] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Ago">
          <template #default="s">
            {{ numberFormat.format(s.row[8] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Set">
          <template #default="s">
            {{ numberFormat.format(s.row[9] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Out">
          <template #default="s">
            {{ numberFormat.format(s.row[10] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Nov">
          <template #default="s">
            {{ numberFormat.format(s.row[11] || 0) }} km.
          </template>
        </el-table-column>
        <el-table-column label="Dez">
          <template #default="s">
            {{ numberFormat.format(s.row[12] || 0) }} km.
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

//import { ElNotification, ElMessageBox } from "element-plus";
//import { ElNotification } from "element-plus";
export default {
  name: "VehicleConsumptionReport",
  data: () => ({
    isLoading: false,
    consumptions: [],
    cars: [],
    currentYear: new Date().getFullYear(),
    car: null,
    numberFormat: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
  }),
  components: {
    VueApexCharts,
  },
  watch: {
    currentYear() {
      this.fetchConsumption();
    },
  },
  mounted() {
    this.fetchConsumption();
  },
  methods: {
    changeYear(n) {
      this.currentYear += n;
    },
    getTotalKm(r) {
      return Number(r.km_end) - Number(r.km_start) || 0;
    },
    selectCar(v) {
      this.car = v;
      this.fetchConsumption();
    },
    fetchCars(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}cars`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.cars = json))
          .catch(() => (this.hasError = true))
          .finally(() => (this.isLoading = false));
    },
    getAreaOptions() {
      return {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          locales: getLocales(),
          defaultLocale: "pt-br",
        },
        //colors: ["#0d84ff"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          formatter: (label) => `${label}`,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        stroke: {
          curve: "smooth",
        },
        /*title: {
          text: "Consumo de veículos nos meses",
          align: "left",
        },*/
        /*grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 1,
          },
        },*/
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          labels: {
            formatter: (v) => this.numberFormat.format(Number(v) || 0),
          },
        },
      };
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    fetchConsumption() {
      this.isLoading = true;

      const url = new URL(`${this.$store.state.apiUrl}cars/consumption`);
      const params = {
        year: this.currentYear,
      };

      if (this.car) params["car_id"] = this.car.uid;

      url.search = new URLSearchParams(params);

      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.consumptions = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    Consumptions() {
      return this.consumptions || [];
    },
    GroupedByPane() {
      const grouped = this.Consumptions.reduce((t, r) => {
        if (!(r.pane in t)) t[r.pane] = [];

        t[r.pane][Number(r._month)] = Number(r.km_end) - Number(r.km_start);

        return t;
      }, {});

      return Object.entries(grouped).map((o) => ({ pane: o[0], ...o[1] }));
    },
    ConsumptionGroupedByPane() {
      const currentYear = this.currentYear;
      const grouped = this.Consumptions?.filter(
        (c) => c._year == currentYear
      )?.reduce((t, r) => {
        if (!(r.pane in t)) t[r.pane] = [];

        t[r.pane].push(calcConsumption(r));

        return t;
      }, {});

      const calculated = Object.entries(grouped).map((o) => ({
        name: o[0],
        data: o[1],
      }));

      /*for (let c of calculated) {
        for (let a = 0; a < c?.data?.length; a++) {
          if (!(a in c?.data)) c["data"][a] = 0;
        }
      }*/

      return calculated;
    },
  },
};

const calcConsumption = (r) => [
  new Date(r._year, Number(r._month) - 1, 15).valueOf(),
  (Number(r.km_end) - Number(r.km_start)) / Number(r.total) || 0,
];
const getLocales = () => [
  {
    name: "pt-br",
    options: {
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      shortMonths: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      days: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      toolbar: {
        exportToSVG: "Baixar SVG",
        exportToPNG: "Baixar PNG",
        exportToCSV: "Baixar CSV",
        menu: "Menu",
        selection: "Selecionar",
        selectionZoom: "Selecionar Zoom",
        zoomIn: "Aumentar",
        zoomOut: "Diminuir",
        pan: "Navegação",
        reset: "Reiniciar Zoom",
      },
    },
  },
];
</script>
<style scoped>
.el-card {
  width: 100%;
}
.m-0 {
  margin-top: 0px !important;
}
li.active {
  background-color: #409eff;
  color: white;
  padding-left: 17px;
}
li.no-padding {
  margin-left: -17px !important;
  margin-right: -17px !important;
}
</style>